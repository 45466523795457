import React, { useEffect, useState } from 'react'
import { ProductsDesign } from '../../styles/MyDairy/Design'
import CustomTable from './CustomTable';
import { useDispatch, useSelector } from 'react-redux';
import { CreditHistoryAction, DestroyCreditAction } from '../../store/actions/MyDairyActions';
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import CustomCreditHistoryTable from './CustomCreditHistoryTable';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { combine } from 'stylis';

function CreditHistory({customer_id, perform, setPerform}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const spinnerLoader = useSelector((state) => state.Authentication.spinnerLoader);
  const creditData = useSelector((state) => state.MyDairy.credithistory.data || []);
  const pagy = useSelector((state) => state.MyDairy.credithistory.pagination || {});
  const role = useSelector((state) => state.Authentication.role);
  const [currentPage, setCurrentPage] = useState(1);
  const [credithistory, setCredithistory] = useState([]);
  const [preCredithistory, setPreCredithistory] = useState([]);
  
  useEffect(()=> {
    setPreCredithistory(credithistory);
    dispatch(CreditHistoryAction({page: currentPage, customer_id: customer_id}, credithistory));
  }, [perform, currentPage])

  useEffect(() => {
    if(creditData){
      setCredithistory([...preCredithistory, ...creditData])
    }
  }, [creditData]);

  const DeleteCredit=(event, id)=>{
    event.stopPropagation();
    
    Swal.fire({
      title: t("Are you sure you want to delete this Credit?"),
      text: t("This action is irreversible and the Credit data will be permanently deleted!"),
      icon: "warning",
      color: "white",
      showCancelButton: true,
      confirmButtonColor: "#28728f",
      cancelButtonColor: "#28728f",
      confirmButtonText: t("Yes, delete it!"),
      cancelButtonText: t('Cancel'),
      background: "#a4c4d1",
      customClass: {
        confirmButton: "swal-button swal-button--danger",
        cancelButton: "swal-button",
        popup: "swal-popup--grey",
        content: "swal-content--grey",
        actions: "swal-actions--center",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(DestroyCreditAction(id, true, setPerform, setCredithistory, t));
      } else {
        return;
      }
    });
  };


  const data = credithistory.length && credithistory.map(item => {
    const { fat, clr, snf, quntity, date, amount, shift } = item;
    return role === 'MyDairy' ? {
      [t("Fat")]: fat,
      [t("CLR")]: clr,
      [t("SNF")]: snf,
      [t("Quantity")]: quntity,
      [t("Date")]: `${moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY')}, ${shift?.toUpperCase()}`,
      [t("Amount")]: amount,
      [t("Action")]: <div className='product-actions'><p onClick={() => navigate(`/buy-milk/edit/${item.id}`)}>Edit</p><p onClick={(e) => DeleteCredit(e, item.id)}>Delete</p></div>
    } :
    {
      [t("Fat")]: fat,
      [t("CLR")]: clr,
      [t("SNF")]: snf,
      [t("Quantity")]: quntity,
      [t("Date")]: `${moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY')}, ${shift?.toUpperCase()}`,
      [t("Amount")]: amount,
    }
  });

  const handleScroll = () => {
    const scrolablePage = document.querySelector(".credit .scroll-tr");
    const scrollTop = scrolablePage.scrollTop;
    const scrollHeight = scrolablePage.scrollHeight;
    const clientHeight = scrolablePage.clientHeight;
    const scrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;

    if (!spinnerLoader && scrollPercentage >= 99 && pagy?.last > currentPage && pagy?.next) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    const scrolablePage = document.querySelector(".credit .scroll-tr");
    scrolablePage?.addEventListener("scroll", handleScroll);

    return () => {
      scrolablePage?.removeEventListener("scroll", handleScroll);
    };
  }, [pagy?.last > currentPage && pagy?.next, currentPage, spinnerLoader]);

  return (
    <div className='credit'>
      {data.length ? 
        <CustomCreditHistoryTable data={data} tableName={t('Credit History')} buttons={role === 'MyDairy' && <button onClick={()=> navigate('/set-parameters')}>{t("Buy Milk")}</button>}/> :
        role === 'MyDairy' && <div className='no-products'>
          <p>{t("There is no Credit History yet please add new one by clicking below")}</p>
          <button onClick={()=> navigate('/set-parameters')}>{t("Buy Milk")}</button>
        </div>}
    </div>
  )
}

export default CreditHistory;