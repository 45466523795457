import styled from "styled-components";

export const SignupDesign = styled.div`
.full-input-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: flex-start;
  gap: 5%;
}

.password-input {
  display: flex;
  align-items: center;
}

.eye-buttton {
  margin-left: -50px;
}

.error {
  display: block;
  text-align: left;
  margin-left: 15px;
}

.lable-input {
  width: 47%;
}

form {
  text-align: center;
  background-color: #e9e6e2;
  margin: 5vh 10%;
  padding: 10px;
  border: none;
  width: 80%;
  max-width: 600px;
  margin: 5vh auto;
}

input {
  margin: 10px;
  border-radius: 10px;
  padding: 10px;
  border: none;
  width: 90%;
  display: inline-block;
}

.full {
  width: 100%
}

.full input {
  width: 95%
}

label {
  display: block;
  margin: 10px;
  text-align: left;
  margin-left: 24px;
}

button {
  background-color: #28728f;
  border: none;
  color: white;
  padding: 15px 32px;
  border-radius: 10px;
  font-size: 15px;
  width: 80%;
  max-width: 300px;
  margin: 10px auto;
  display: block;
  cursor: pointer;
}

.back-img {
  background-image: url(${require('./backgroundimage.png')});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 88.26vh;
  background-color: #f0f0f0;
  width: 100%;
  font-family: Arial, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkbox-row label {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.checkbox-row input {
  margin-right: 10px;
}

.checkbox-row span {
  flex-grow: 1;
}

a {
  color: #28728f;
}

#tems_conditions {
  width: auto;
}

@media screen and (max-width: 768px) {
  form {
    width: 90%;
  }
  input {
    width: calc(100% - 30px);
  }
  button {
    width: 70%;
  }

  .full input {
    width: calc(100% - 35px)
  }
}

@media screen and (max-width: 500px) {
  .full-input-container {
    flex-direction: column;
  }
  .full, .lable-input {
    width: 90%;
  }
}
`;

export const LoginDesign = styled.div`
max-width: 100%;

form {
  text-align: center;
  background-color: #e9e6e2;
  max-width: 400px;
  padding: 3%;
  box-sizing: border-box;
  margin-right: 6%;
  width: 100%;
}

span {
  display: contents;
}

input {
  margin: 0.5em 0;
  border-radius: 30px;
  padding: 15px;
  border: none;
  width: 90%;
}

button {
  background-color: #28728f;
  border: none;
  color: white;
  padding: 1em 2em;
  border-radius: 30px;
  font-size: 15px;
  width: 100%;
  margin: 1em 0;
  cursor: pointer;
}

hr {
  height: 0.5px;
  background-color: black;
  margin: 2em 0;
}

svg {
  margin-left: -50px;
}

.back-img {
  background-image: url(${require("./backgroundimage.png")});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 88.26vh;
  background-color: #f0f0f0;
  font-family: Arial, sans-serif;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.box6 {
  color: #28728f;
  margin-left: 10px;
  font-size: 13px;
  text-align: left;
}

.password-input {
  display: flex;
  align-items: center;
  justify-content: center;
}

.eye-buttton {
  margin-left: -34px;
}

@media (max-width: 768px) {
  form {
    width: 80%;
  }
}

@media (max-width: 480px) {
  form {
    width: 90%;
  }
}

body {
  overflow: hidden;
  margin: 0;
}
`;

export const ForgotPasswordDesign = styled.div`
height: 88.26vh;
display: flex;
align-items: center;
justify-content: center;

.box {
  background-color: #a4c4d1;
  border-radius: 30px;
  width: 80%;
  max-width: 500px;
}

h1 {
  color: #28728f;
  text-align: center;
}

::placeholder {
  color: #a4c4d1;
}

form {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

input {
  border: 2px solid #28728f;
  width: 70%;
  padding: 10px 15px;
  border-radius: 30px;
  margin: 10px 0;
  box-sizing: border-box;
}

button {
  background-color: #28728f;
  border: none;
  color: white;
  padding: 15px;
  border-radius: 30px;
  font-size: 16px;
  width: 50%;
  margin: 20px 0;
  box-sizing: border-box;
  cursor: pointer;
}

@media (max-width: 768px) {
  button {
    width: 70%;
  }

  input {
    width: 90%;
  }
}
`;

export const VerificationDesign = styled.div`
height: 88.26vh;
display: flex;
align-items: center;
justify-content: center;

.box {
  display: flex;
  background-color: #a4c4d1;
  border-radius: 30px;
  width: 80%;
  max-width: 500px;
  height: 50vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h1 {
  color: #28728f;
  text-align: center;
}

.input_contener {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.popup_content_inner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4%
}

.popup_content h3 {
  margin-top: 0;
  color: #000;
}

.popup_content label {
  margin-bottom: 20px;
}

.resent {
  cursor: pointer;
  color: #28728f;
  font-style: italic;
}

.resent_sms {
  color: green;
  text-align: center;
}

.otp-input-container {
  display: flex;
  justify-content: center;
}

.otp-input-container input[type="text"] {
  width: 50px;
  height: 50px;
  margin: 0 5px;
  text-align: center;
  font-size: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.popup_content select {
  width: 96%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 20px;
  color: #000;
}


button {
  background-color: #28728f;
  border: none;
  color: white;
  padding: 15px;
  border-radius: 30px;
  font-size: 16px;
  width: 70%;
  margin: 20px 0;
  box-sizing: border-box;
  cursor: pointer;
}
`;

export const ResetPasswordDesign = styled.div`
height: 88.26vh;
display: flex;
align-items: center;
justify-content: center;

div {
  display: flex;
  background-color: #a4c4d1;
  border-radius: 30px;
  width: 80%;
  max-width: 500px;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 }

h1 {
  color: #28728f;
  text-align: center;
}

.password-input {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: -webkit-fill-available;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  padding: 12px;
}

.eye-buttton {
  margin-left: -33px;
}

.error {
  padding-bottom: 16px;
}

input {
  border-radius: 20px;
  border: 2px solid #28728f;
  padding: 10px 20px;
  width: 50%;
}

button {
  background-color: #28728f;
  border-radius: 20px;
  padding: 15px 30px;
  border: none;
  font-size: 16px;
  color: white;
  width: 40%;
}

@media (max-width: 600px) {
  div {
    padding: 20px 10px;
  }

  h1 {
    font-size: 20px;
  }

  input {
    padding: 10px 15px;
  }

  button {
    padding: 12px 25px;
  }
}

@media (max-width: 400px) {
  div {
    padding: 15px 10px;
  }

  h1 {
    font-size: 18px;
  }

  input {
    padding: 8px 12px;
  }

  button {
    padding: 10px 20px;
    font-size: 14px;
  }
}
`;

export const ResetPasswordSuccessfullyDesign = styled.div`
height: 88.26vh;
display: flex;
align-items: center;
justify-content: center;
div {
  display: flex;
  background-color: #a4c4d1;
  border-radius: 30px;
  width: 80%;
  max-width: 500px;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.verifcation-tick {
  color: #4caf50;
  font-size: 40px;
  margin-top: 20px;
}

h1 {
  color: white;
  font-style: italic;
  font-size: 2em; 
  margin-bottom: 20px;
}

a {
  color: #28728f;
  margin-top: -10px;
}

@media (max-width: 600px) {
  div {
    padding: 10% 10px; 
    margin: 20% auto; 
  }

  h1 {
    font-size: 1.5em;
  }

  p {
    font-size: 1.2em;
    margin-top: 15px;
  }
}

@media (max-width: 400px) {
  div {
    padding: 8% 10px; 
    margin: 15% auto; 
  }

  h1 {
    font-size: 1.2em;
  }

  p {
    font-size: 1em;
    margin-top: 10px;
  }
}
`;

export const HeadersDesign = styled.div`
margin-bottom: 90px;

.login {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.button {
  background-color: #28728f;  
  display: inline-block;
  padding: 2px 72px;
  font-size: 17px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  border: none;
  border-radius: 25px;
  position: absolute;
  right: 20px;
  bottom: 26px;
}

.login-part1 {
  height: 28px;
  margin-left: -55px;
  margin-top: 6px;
  width: 80%;
}

.login-part2 {
  height: 10px;
  margin-right: -65px;
  margin-top: 0px;
  width: 20%;
  img {
    height: 25px;
    margin-top: 3px;
    margin-left: -35px;
    border-radius: 90px;
  }
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  color: #000;
  height: auto;
  background-color: #efefef;
  font-size: larger;
  z-index: 1;
}

.log-menu {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  flex-direction: row;
}

.header:hover {
  background-color: #DADBDD;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0 20px;
}

nav ul {
  list-style-type: none;
  display: flex;
  align-items: center;
  height: 100%;
}

nav ul li {
margin-right: 20px;
cursor: pointer;
}

.header-line {
  border: none;
  border-top: 1px solid #ccc;
  margin: 0;
}

.logo {
  margin-left: 20px;
  cursor: pointer;
}

.logo img {
  width: 27%;
}

.nav-menu-options {
  display: none;
}

.menu-btn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 30px;
  height: 20px;
  cursor: pointer;
}

.menu-btn__line {
  width: 100%;
  height: 2px;
  background-color: #000;
  transition: all 0.3s ease-in-out;
}

.toggel-options {
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.toggel-options:hover {
  background-color: #fff;
}



.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


@media screen and (max-width: 768px) {
  .nav-options {
    display: none;
}

  .nav-menu-options {
    display: contents;
}

.log-menu {
  align-items: center;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: 100%;
  padding: 0 20px;
}

nav ul {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

nav ul li {
  margin-right: 20px;
  cursor: pointer;
}
}
`;

export const MainHeaderDesign = styled.div`
margin-bottom: 85px;

.logo {
  margin-left: 20px;
  cursor: pointer;
}

.logo img {
  width: 80px;
}

.profile {
  display: flex;
  justify-content: flex-end;
}

.profile img{
  border-radius: 90px;
  width: 60px;
  height: 60px;
  cursor: pointer;
}

.notification img {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.profile-toggel {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  margin-right: 20px;
  gap: 20%
}

.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  color: #000;
  height: auto;
  background-color: #efefef;
  font-size: larger;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
}

@media (max-width: 450px) {
  .profile-toggel {
    gap: 1%
  }
}
`;

export const ToggleDesign = styled.div`
.toggle-container {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  flex-direction: row;
}

p {
  font-weight: bold;
  color: #28728f;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #4fa8de;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 100%;
}

.switch.on .slider {
  background-color: #28728f;
  ;
}

.switch.on input:checked + .slider:before {
  transform: translateX(26px);
}

.switch.off .slider {
  background-color: #ccc;
}
`;

export const SliderMenuDesign = styled.div`
.logo {
  position: absolute;
  top: 0px;
  margin-left: 20px;
  cursor: pointer;
}

.logo img {
  width: 80px;
}

.side-menu {
  position: fixed;
  z-index: 1;
  top: 0;
  left: -270px;
  height: 100%;
  background-color: #fff;
  padding-top: 60px;
  transition: left 0.3s ease-in-out;
  @media (max-width: 400px) {
    width: 70%;
    left: -100%;
  }
}

.side-menu.open {
  left: 0;
  width: 264px;
}

.close-button {
  position: absolute;
  top: 20px;
  left: 20px;
  color: #fff;
  cursor: pointer;
  font-size: 24px;
}

.menu-name {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #a4c4d1;
  }
}

.menu-logut {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 10px;
  gap: 10px;
  cursor: pointer;
}

ul {
  list-style: none;
  padding: 0;
  margin-top: 25px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 150px);
}

li {
  padding: 15px;
  color: #28728f;
  font-weight: bold;
  font-size: large;
  transition: background-color 0.3s ease;
}

.logout {
  position: absolute;
  bottom: 50px;
  color: #28728f;
  font-weight: bold;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-top: 1.5px solid #a4c4d1;
  width: calc(100% - 40px);
  justify-content: center;
}

.logout-icon {
  font-size: 20px;
  margin-right: 10px;
}

p {
  font-size: large;
  font-weight: bold;
}

.sub-menu {
  padding: revert;
  margin: 0px;
}

.menu-name img{
  height: 25px;
  width: 25px;
}
`;

export const CustomLoaderDesign = styled.div`
body {
  overflow: hidden;
}

.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(0, 0, 0, 0.834);
  z-index: 1;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 8px solid;
  border-color: #28728f transparent #28728f transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}
`;