import logoImage from '../../assets/images/MilkDairyLogo/Blue Fresh Milk Logo Template (1).png'
import profileAvatar from '../../assets/images/login.jpg'
import { useEffect, useState } from "react";
import { MainHeaderDesign } from "../../styles/Design";
import ToggleSwitch from "./Toggle";
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import notificationIcon from '../../assets/images/notification_icon.png';
import Notification from '../Customer/Notification';

function MainHeader() {
	const [logo] =  useState(logoImage)
  const [url, setUrl] = useState(profileAvatar);
  const [isNotificationOpen, setNotificationOpen] = useState(false);
  const navigate = useNavigate();
  const profile = useSelector((state) => state.Authentication.profile || {});
  const role = useSelector((state) => state.Authentication.role);
  const customer = useSelector((state) => state.MyDairy.customer || {});

  useEffect(()=> {
    if(role === 'MyDairy' && profile.image?.url) {
      setUrl(profile.image?.url);
    }

    if(role === 'Customer' && customer.image?.url) {
      setUrl(customer.image?.url);
    }
  }, [profile, customer, role])

  const handleNotificationToggle = () => {
    setNotificationOpen(!isNotificationOpen);
  };

  console.log("profile", profile)
  return (
    <MainHeaderDesign>
      {isNotificationOpen && (
        <div className="overlay" style={{overflow: 'hidden'}}>
        </div>
      )}
      <div className="container"> 
        <div className="log-menu">
          <div className="logo" onClick={()=> navigate('/')}>
            <img src={logo} alt="Milk Dairy"/>          
          </div>
        </div>
        <div className='profile-toggel'>
          <ToggleSwitch />
            <div className="notification">
              <img src={notificationIcon} alt="Milk Dairy" onClick={() => {handleNotificationToggle(); }} />
              <Notification isOpen={isNotificationOpen} onClose={handleNotificationToggle} />
            </div>
          <div className="profile"><img src= {url} alt="Milk Dairy" onClick={()=> role === 'MyDairy' ? navigate('/account') : ''} /></div>
        </div>
      </div>
    </MainHeaderDesign>
  );
}

export default MainHeader;
